<template>
    <section
        class="base-text-image"
        :class="landscape ? 'base-text-image--landscape' : null"
        :style="{
            '--background-color': backgroundColor,
            '--text-color': textColor
        }"
    >
        <div
            ref="section"
            class="base-text-image__wrapper"
        >
            <div class="base-text-image__content">
                <h2
                    v-if="!!$slots.title || pending"
                    class="base-text-image__title"
                >
                    <Skeletor v-if="pending" />
                    <slot
                        v-else
                        name="title"
                    />
                </h2>

                <div
                    v-if="!!$slots.description || pending"
                    class="base-text-image__description"
                >
                    <Skeletor v-if="pending" />
                    <slot
                        v-else
                        name="description"
                    />
                </div>

                <div
                    v-if="!!$slots.text || pending"
                    class="base-text-image__text"
                >
                    <Skeletor v-if="pending" />
                    <Skeletor v-if="pending" />
                    <Skeletor v-if="pending" />

                    <slot
                        v-else
                        name="text"
                    />
                </div>
            </div>

            <div
                v-if="image || pending"
                ref="imageElement"
                v-motion
                class="base-text-image__image"
                :initial="{ 
                    opacity: 0, 
                    y: 0, 
                    scale: 1.05 
                }"
                :visible="{ 
                    opacity: 1, 
                    y: 0, 
                    scale: 1, 
                    transition: {
                        duration: 1000,
                        ease: '0.4, 0, 0.2, 1',
                        delay: 0
                    }
                }"
            >
                <Skeletor
                    v-if="pending"
                    :shimmer="false"
                    height="100%"
                />
                <BaseImage
                    v-else-if="image"
                    :fade-in-view="false"
                    :image="image"
                    :width="image.width"
                    :height="image.height"
                    :sizes="imageSizes"
                />
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import type { ColorPalette } from '~/composables/useColorPalette';
import { useColorPalette } from '~/composables/useColorPalette';

import BaseImage from '~/components/BaseImage.vue';
import { Skeletor } from 'vue-skeletor';

import { useScrollAnimations } from '~/composables/useScrollAnimations';

defineOptions({
    name: 'BaseTextImage',
});

const { resolveColor, getTextColor } = useColorPalette();

interface Props {
    backgroundColor?: keyof ColorPalette;
    image?: {
        title: string,
        width: number,
        height: number
        [key: string]: any,
    };
    pending?: boolean;
    landscape?: boolean;
    useParallax?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
    backgroundColor: () => 'bright-orange',
    pending: () => false,
    image: undefined,
    landscape: false,
    useParallax: false
});

const backgroundColor = computed(() => resolveColor(props.backgroundColor));
const textColor = computed(() => getTextColor(props.backgroundColor));
const imageSizes = computed(() => {
    let desktopWidth = '660px';
    if (props.landscape) {
        desktopWidth = '929px';
    }
    return `(min-width: 992px) ${desktopWidth}, 100vw`;
});

const section = ref(null);
const imageElement = ref(null);

onMounted(() => {
    if (props.useParallax) {
        const { parallax } = useScrollAnimations();

        parallax(
            imageElement.value,
            {
                offset: .65,
                trigger: section.value
            });
    }
});
</script>

<style lang="less">
.base-text-image {
    background-color: var(--background-color);
    color: var(--text-color);
    overflow: hidden;

    @media @q-lg-min {
        padding-top: 10.25rem;
    }
}

.base-text-image__wrapper {
    .base-grid(var(--grid-maxWidth-page), 1);

    margin: 0 auto;
}

.base-text-image__content {
    grid-column: first/right-side-gutter;
    grid-row: 1;

    display: flex;
    padding: var(--spacing-2xl) 0 var(--spacing-md) 0;
    flex-direction: column;
    gap: var(--spacing-md);

    @media @q-lg-min {
        grid-column: first/right-gutter;

        padding: 0 0 10.25rem;
        gap: var(--spacing-lg);
        width: 61.5%;
    }
}

.base-text-image__image {
    grid-column: 1/-1;
    grid-row: 2;

    display: flex;
    align-self: stretch;
    width: 100%;
    max-height: 300px;

    figure {
        display: flex;
        flex: 0 0 100%;

        picture {
            display: flex;
            flex: 0 0 100%;
        }

        img {
            height: auto;
        }
    }

    @media @q-lg-min {
        grid-column: second/-1;
        grid-row: 1;
        justify-self: end;

        max-height: none;
        width: 71.1%;
    }
}

.base-text-image__description {
    .typography-text-sm();
}

.base-text-image__text {
    .typography-text();
}


.base-text-image--landscape {
    @media @q-lg-min {
        .base-text-image__content {
            grid-column: first;
            width: auto;
        }

        .base-text-image__image {
            grid-column: second/-1;
            width: auto;
        }
    }
}

.base-text-image--plain-link {
    .base-text-image__content a[href] {
        text-decoration: none;

        &:hover, &:active, &:focus-visible {
            text-decoration: underline;
        }
    }
}
</style>
